.n-wrapper{
    height: 10vh;
    display: flex;
    justify-content: space-between;
    /* background-color: var(--cardColor); */
}

.n-left{
    flex: 1;
    align-items: center;
    display: flex;
    gap: 2rem;
    
}

.n-name{
    
    font-size: 2rem;
    font-weight: bold;
    color: var(--orange);
    display: flex;
    justify-content: center;
    align-items: center;
   
}


.n-right{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-weight: 400;

}
.n-list{
    flex: 10;
}
.n-list ul{
    display: flex;
    gap: 2rem;
    margin-right: 4rem;
}

.n-list ul li:hover{
    cursor: pointer;
    color: var(--orange);

}

.n-button{
    flex: 2;
    color: var(--orange);
    border-radius: 0.5rem;
    border: 1px solid var(--orange);
}
.button{
    display: flex;
    gap: 1rem;
    /* margin-right: 1rem; */
    
}
@media screen and (max-width: 480px) {
    .n-list{
        display: none;
    }
    .n-button{
        display: none;
    }
  }
    